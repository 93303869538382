.message-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    min-height:40px;
    max-height: 40px;
    // min-width:160px;
    // max-width:160px;
    margin-top: 2px;
    margin-bottom:2px;
    border-radius: 20px;
    border-style: solid ;
    border-color: #ddd;
    border-width: 1px;
}