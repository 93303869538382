.icon {
  width: 20px;
  height: 20px;
  fill: var(--N800);
}

.isSelected {
  fill: white;
}

.isDisabled {
  fill: gray;
}

.isButtonSelected {
  background: #253858;
}

.isButtonUnselected {
  background: white;
}

.isButtonUnselected:hover {
  background: rgba(0, 0, 0, 0.04);
}

.isButtonDisabled {
  background: white;
}