/*
BASIC STYLES
*/

.blockOuter {
  /* padding-bottom: 15px; */
  font-size: 16px;
  line-height: 1.5;
  /* transition: all 0.2s; */
  font-weight: normal;
}

.block {
  border-left: 2px solid white;
  border-color: white;
  transition: all 0.2s;
}

.block {
  /* content: ""; */
  /* transition: all 0.2s; */
  margin: 0px;
  
}

.blockContent {
  padding: 3px 0;
  /* transition: font-size 0.2s; */
  /* display: inline-block; */

}

/*
NESTED BLOCKS
*/

.blockGroup .blockGroup {
  margin-left: 1.5em;
}

.blockGroup .blockGroup > .blockOuter {
  position: relative;
}

/* make sure embeded image has 100% width with its blockgroup parent for now. Will change when introduced adjustable image */ 
.blockGroup img {
  width: 100%;
}

/* HEADINGS*/
.blockOuter[data-heading-type="1"]
  > .block
  > div:first-child {
  font-size: 3em;
  font-weight: bold;
}

.blockOuter[data-heading-type="2"]
  > .block
  > div:first-child {
  font-size: 2em;
  font-weight: bold;
  margin-top:0.8em;
}

.blockOuter[data-heading-type="3"]
  > .block
  > div:first-child {
  font-size: 1.3em;
  font-weight: bold;
  margin-top:1em;
}

/* LISTS */

.block > div:first-child::before {
  content: "";
  transition: all 0.2s;
  margin-left: 0px;
}

.blockOuter[data-list-type="oli"]
  > .block
  > div:first-child::before {
  content: attr(data-position);
  margin-right: 1.2em;
  padding-left: 0px;
}

.blockOuter[data-list-type="li"]
  > .block
  > div:first-child::before {
  content: "•";
  margin-right: 1.2em;
  /* display: list-item; */
  /* list-style-type: circle; */
  /* list-style-position: inside; */
  padding-left: 0px;
  /* margin-left: 0.2em; */
}


.blockOuter[data-list-type="li"]
  > .block
  > .blockGroup
  > .blockOuter[data-list-type="li"]
  > .block
  > div:first-child::before {
  content: "◦";
  /* font-size: 1.2em; */
  font-family: arial;
}

.blockOuter[data-list-type="li"]
  > .block
  > .blockGroup
  > .blockOuter[data-list-type="li"]
  > .block
  > .blockGroup
  .blockOuter[data-list-type="li"]
  > .block
  > div:first-child::before {
  content: "▪";
}


/* Tasklist */
.blockOuter[data-list-type="tli"] > .block > div:first-child[data-checked=true] {
  text-decoration: line-through;
  color: grey;
}


/* PLACEHOLDERS*/

.blockContent > div {
  display: inline;
}
.blockContent.isEmpty div::before,
.blockContent.isFilter div::before {
  /*float: left; */
  content: "";
  color: #aeb8c2;
  pointer-events: none;
  height: 0;
  /* width: 0; */
  position: absolute;
  font-style: italic;
}

.blockContent > div > a {
  cursor: pointer;
}

/* TODO: would be nicer if defined from code */
.blockContent.isEmpty.hasAnchor div::before {
  content: "Enter text or type '/' for commands";
}

/* Inside of first layer diff remove content */
.blockContent.isEmpty.hasAnchor div div::before {
  content: "";
}

/* Untitled for first line */ 
.blockGroup > .blockOuter:first-child > .block:not([data-list-type]) > .blockContent.isEmpty div::before {
  content: "Untitled";
}

.blockContent.isFilter.hasAnchor div::before {
  content: "Type to filter";
}

/* Inside of first layer diff remove content */
.blockContent.isFilter.hasAnchor div div::before {
  content: "";
}

[data-heading-type] > .blockContent.isEmpty div::before {
  content: "Heading";
}

/* Inside of first layer diff remove content */
[data-heading-type] > .blockContent.isEmpty div div::before {
  content: "";
}

[data-list-type] > .blockContent.isEmpty div::before {
  content: "List";
}

/* Inside of first layer diff remove content */
[data-list-type] > .blockContent.isEmpty div div::before {
  content: "";
}